import React from 'react'
import TransporterFilterTab from './TransporterFilterTab'
import { Table, Alert, Image, Form } from 'react-bootstrap'
import TransporterTable from '../ui/TransporterTable'
import { _filterConnaissement } from '../../utils/api/apiControlerFunctions'
import TableLoader from '../ui/Loader/TableLoader'
import TableTitleTransporter from './TableTitleTransporter'

export default function TableTransporter({ tableTransporterProps }: any) {
  const {
    isTransporter,
    isLoading,
    connaissementData,
    setSelectedConnaissement,
    handleShowDetailConnaiss,
    handleShowQrcode,
    isError,
    isFiltering,
    isEmpty,
    noResult,
    isErrorConnaiss,
    status,
    filteringData,
    setFilteringData,
    setIsLoading,
    tokenTab,
    currentPage,
    setIsError,
    setConnaissementData,
    itemPerPage,
    setTotalPages,
    setIsFiltering,
    statusOff,
  } = tableTransporterProps

  const billOfilterProps = {
    filteringData,
    setFilteringData,
    isTransporter,
    isFiltering,
    isEmpty,
    isLoading,
    setIsLoading,
    tokenTab,
    currentPage,
    setIsError,
    setConnaissementData,
    itemPerPage,
    setTotalPages,
    setIsFiltering,
    status,
  }

  return (
    <Form
      onSubmit={(event) => {
        if (status !== 'EMBARQUEMENT_REFUSE') {
          _filterConnaissement(
            event,
            status,
            filteringData,
            setIsLoading,
            tokenTab,
            currentPage,
            setIsError,
            setConnaissementData,
            itemPerPage,
            setTotalPages,
            setIsFiltering
          )
        } else {
          _filterConnaissement(
            event,
            statusOff,
            filteringData,
            setIsLoading,
            tokenTab,
            currentPage,
            setIsError,
            setConnaissementData,
            itemPerPage,
            setTotalPages,
            setIsFiltering
          )
        }
      }}
    >
      <Table
        striped
        hover
        responsive
        className='transport-table responsive-font-small border w-100'
      >
        <TableTitleTransporter isTransporter={isTransporter} />
        <TransporterFilterTab billOfilterProps={billOfilterProps} />
        <tbody>
          {!isLoading &&
            connaissementData?.length > 0 &&
            connaissementData?.map((connaissement: any, indx: number) => {
              return (
                <React.Fragment key={indx}>
                  {connaissement?.dernierEtat?.evenementConnaissement === status && (
                    <TransporterTable
                      setSelectedConnaissement={setSelectedConnaissement}
                      connaissement={connaissement}
                      handleShowDetailConnaiss={handleShowDetailConnaiss}
                      handleShowQrcode={handleShowQrcode}
                    />
                  )}
                </React.Fragment>
              )
            })}
          {isLoading &&
            Array.from({ length: 5 }).map((_: any, index: number) => (
              <TableLoader colNumber={5} key={index} />
            ))}

          {!isLoading && !isError.error && isFiltering && connaissementData?.length === 0 && (
            <tr className=''>
              <td></td>
              <td colSpan={7} className='text-center'>
                <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
              </td>
              <td></td>
            </tr>
          )}
          {!isLoading && isErrorConnaiss.error && (
            <tr className=''>
              <td colSpan={isTransporter ? 10 : 9} className='text-center'>
                <Alert variant='danger'>{isErrorConnaiss?.message}</Alert>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Form>
  )
}
