import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { DetailConnaissementModal } from '../../../component/ui/Modal/Modals'
import ConnaissementService from '../../../services/connaissements/ConnaissementServices'
import userStore from '../../../stores/userStore'
import { errorType } from '../../../definitions/errorType'
import {
  ToastDeleteSuccess,
  ToastError,
  ToastInfo,
  ToastUpdateUserSuccess,
} from '../../../component/ui/Toast/Toastes'
import { QrcodeConnaissementModal } from '../../../component/ui/Modal/ConnaissementModals'
import {
  _connaissementDataTable,
  _filterConnaissement,
  _refreshToken,
} from '../../../utils/api/apiControlerFunctions'
import { useReactToPrint } from 'react-to-print'
import noResult from '../../../styles/images/no_result.png'

import PaginationZero from '../../../component/ui/PaginationZero'
import ItemsLimiter from '../../../component/billOfLading/ItemsLimiter'
import { useOutletContext } from 'react-router-dom'
import TableTransporter from '../../../component/transporter/TableTransporter'

export default function EmbarquementRefuse() {
  const { headerData, setHeaderData } = useOutletContext<any>()
  const dataStore = userStore((state: any) => state)

  const isTransporter: boolean =
    dataStore?.roles && dataStore?.roles[0]?.name === 'transporteur'

  const title = 'EMBARQUEMENT REFUSE'
  const statusOff = 'OFFICIALISE'
  const status = 'EMBARQUEMENT_REFUSE'
  const borderColor = 'red-2'
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isErrorConnaiss, setIsErrorConnaiss] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>('')
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)

  const [connaissementData, setConnaissementData] = React.useState<any>([])
  const [tokenTab, setTokenTab] = React.useState<any>([])
  const [selectedConnaissement, setSelectedConnaissement] = React.useState<any>({})

  const evnt = undefined;
  const [filteringData, setFilteringData] = React.useState<any>({
    numeroConnaissement: '',
    expediteur_denomination: '',
    destinataire: '',
    idNavire: '',
    evenementConnaissement: '',
    dateDepart: '',
    nomIleArrivee: '',
    dateArrivee: '',
  })

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [itemPerPage, setItemPerPage] = React.useState<number>(40)

  const isEmpty =
    filteringData?.numeroConnaissement === '' &&
    filteringData?.expediteur_denomination === '' &&
    filteringData?.destinataire === '' &&
    filteringData?.idNavire === '' &&
    filteringData?.evenementConnaissement === '' &&
    filteringData?.dateDepart === '' &&
    filteringData?.nomIleArrivee === '' &&
    filteringData?.dateArrivee === ''

  //////////////////
  //Toastes
  /////////////////
  //Toast Delete success
  const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false)
  const toggleShowDeleteSuccess = () => setShowDeleteSuccess(!showDeleteSuccess)

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false)
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess)

  const [showOrderError, setShowOrderError] = React.useState<boolean>(false)
  const toggleShowOrderError = () => setShowOrderError(!showOrderError)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

  //////////////////
  //Modals
  /////////////////
  //detail connaissement
  const [showDetailConnaiss, setShowDetailConnaiss] = React.useState(false)
  const handleCloseDetailConnaiss = () => {
    setShowDetailConnaiss(false)
  }
  const handleShowDetailConnaiss = () => setShowDetailConnaiss(true)

  // Show qrcode
  const [showQrcode, setShowQrcode] = React.useState(false)
  const handleCloseQrcode = () => {
    setShowQrcode(false)
  }
  const handleShowQrcode = () => setShowQrcode(true)

  const printRef = React.useRef(null)

  // Utiliser useReactToPrint pour gérer l'impression
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })

  React.useEffect(() => {
    const transportTokens = dataStore?.company?.map((comp: any) => {
      return comp.access_token
    })
    setTokenTab(transportTokens)
    setHeaderData({
      ...headerData,
      title: title,
      borderColor: borderColor,
    })
  }, [])

  React.useEffect(() => {
    _connaissementDataTable(
      statusOff,
      currentPage,
      setTotalPages,
      itemPerPage,
      setIsLoading,
      tokenTab,
      setIsError,
      setConnaissementData,
      setIsErrorConnaiss,
      dataStore
    )
  }, [tokenTab])

  React.useEffect(() => {
    if (isEmpty) {
      _connaissementDataTable(
        statusOff,
        currentPage,
        setTotalPages,
        itemPerPage,
        setIsLoading,
        tokenTab,
        setIsError,
        setConnaissementData,
        setIsErrorConnaiss,
        dataStore
      )
      setIsFiltering(false)
    }
  }, [filteringData])

  React.useEffect(() => {
    if (isFiltering) {
      _filterConnaissement(
        evnt,
        statusOff,
        filteringData,
        setIsLoading,
        tokenTab,
        currentPage,
        setIsError,
        setConnaissementData,
        itemPerPage,
        setTotalPages,
        setIsFiltering
      )
    } else {
      _connaissementDataTable(
        statusOff,
        currentPage,
        setTotalPages,
        itemPerPage,
        setIsLoading,
        tokenTab,
        setIsError,
        setConnaissementData,
        setIsErrorConnaiss,
        dataStore
      )
    }
  }, [currentPage, itemPerPage])

  const handleFilterConnaissement = (event: any) => {
    const value = event?.target?.value
    if (value?.length > 2) {
      const filteredData = connaissementData.filter((item: any) => {
        return (
          item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
          item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
          item?.expediteur?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
          item?.dernierEtat?.evenementConnaissement
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
        )
      })

      setConnaissementData(filteredData)
    }
    if (value.length === 0) {
      _connaissementDataTable(
        statusOff,
        currentPage,
        setTotalPages,
        itemPerPage,
        setIsLoading,
        tokenTab,
        setIsError,
        setConnaissementData,
        setIsErrorConnaiss,
        dataStore
      )
    }
  }

  const connaissementDataTable = async (
    currentPage: number,
    setTotalPages: any,
    itemPerPage: number
  ) => {
    setIsLoading(true)
    let newSortData: any[] = []
    try {
      const connaissementPromises = tokenTab?.map((token: string) =>
        ConnaissementService.getTransporterByStatus(token, 0, itemPerPage, 'OFFICIALISE')
          .then((response: any) => {
            newSortData = [...newSortData, ...response?.data?.content]
          })
          .catch((error: any) => {
            if (error) {
              console.log(error)
              setIsError({
                error: true,
                message: error?.response?.data?.message,
              })
            }
          })
      )

      await Promise.all([...(connaissementPromises || [])])

      const sortedData = newSortData?.sort((a: any, b: any) => b.id - a.id)
      setConnaissementData(sortedData)
      setTotalPages(1)
      // setTotalPages(response?.data?.totalPages)
      setIsLoading(false)
      setIsErrorConnaiss({
        error: false,
        message: '',
      })
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error
          ? error?.response?.data?.error
          : error?.message === 'Network Error'
          ? 'Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard.'
          : error?.message,
      })
      setIsLoading(false)

      if (error?.response?.data?.error === 'invalid_token') {
        setIsError({
          error: true,
          message: error?.response?.data?.error,
        })
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const tableTransporterProps = {
    isTransporter,
    isLoading,
    connaissementData,
    setSelectedConnaissement,
    handleShowDetailConnaiss,
    handleShowQrcode,
    isError,
    isFiltering,
    isEmpty,
    noResult,
    isErrorConnaiss,
    status,
    filteringData,
    setFilteringData,
    setIsLoading,
    tokenTab,
    currentPage,
    setIsError,
    setConnaissementData,
    itemPerPage,
    setTotalPages,
    setIsFiltering,
   statusOff,
  }
  //Modals props
  const detailOrderModalProps = {
    showDetailConnaiss,
    selectedConnaissement,
    handleCloseDetailConnaiss,
    handlePrint,
    printRef,
  }
  const qrCodeModalProps = { showQrcode, selectedConnaissement, handleCloseQrcode }

  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
  const toastDeleteSuccessProps = { showDeleteSuccess, toggleShowDeleteSuccess }
  const toastErrorProps = { showOrderError, toggleShowOrderError, isError }
  const toastInfoProps = { showInfo, toggleShowInfo, infoOrder }

  return (
    <div className='p-1 p-sm-3'>
      <div>
        <Form.Group className='mb-3' controlId='searchBar'>
          <InputGroup className=''>
            <InputGroup.Text id='basic-addon1' className='bg-secondary border'>
              <i className='ri-search-line text-light'></i>
            </InputGroup.Text>
            <Form.Control
              className='border'
              type='text'
              autoComplete='on'
              placeholder='Recherche'
              onChange={handleFilterConnaissement}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <TableTransporter tableTransporterProps={tableTransporterProps} />
      <div className='d-flex align-items-center'>
        <PaginationZero
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>

      <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />
      <QrcodeConnaissementModal qrCodeModalProps={qrCodeModalProps} />
      {/* <UpdateToDemandeModal updateToDemandeModalProps={updateToDemandeModalProps} />
          <UpdateMultiToDemandeModal
            updateMultiToDemandeModalProps={updateMultiToDemandeModalProps}
          /> */}
      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
      <ToastDeleteSuccess toastDeleteSuccessProps={toastDeleteSuccessProps} />
      <ToastError toastErrorProps={toastErrorProps} />

      <ToastInfo toastInfoProps={toastInfoProps} />
    </div>
  )
}
