import React from 'react'
import {  Container, Dropdown, Form, InputGroup, Spinner, Table } from 'react-bootstrap'
import FloatingAddCompanyButton from '../../component/ui/FloatingAddCompanyButton'
import ModalUpdateCompany from '../../component/company/ModalUpdateCompany';
import userStore from '../../stores/userStore';
import { _getCompaniesData } from '../../utils/api/totaraApi';
import { useOutletContext } from 'react-router-dom';



export default function Companies() {

  const {companiesData, setCompaniesData} = useOutletContext<any>()

  const dataStore = userStore((state: any) => state)

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [companiesData, setCompaniesData] = React.useState<any>();
  const [selectedCompanyData, setSelectedCompanyData] = React.useState<any>();

  const [showUpdateCompany, setShowUpdateCompany] = React.useState<boolean>(false);

  const handleCloseUpdateCompany = () => setShowUpdateCompany(false);
  const handleShowUpdateCompany = () => setShowUpdateCompany(true);

  React.useEffect(() => {
    // getCompaniesData()
    if(dataStore.token !== ''){
      _getCompaniesData(dataStore.token, setCompaniesData, setIsLoading)
    }

  }, [])

  const modalUpdateCompanyProps = {showUpdateCompany, handleCloseUpdateCompany, selectedCompanyData, setSelectedCompanyData, setIsLoading}
  const floatingAddCompanyButtonProps = {setCompaniesData, setIsLoading}

  return (
    <div className='p-1 p-lg-3 pb-5 mb-5 w-100'>
      <h3 className='text-secondary'>Compagnies</h3>
      <div>
        <Form.Group className='mb-3 ' controlId='formBasicEmail'>
          <InputGroup>
            <InputGroup.Text id='basic-addon1' className='bg-secondary border'>
              <i className='ri-search-line text-light'></i>
            </InputGroup.Text>
            <Form.Control
              className='border'
              type='text'
              autoComplete='on'
              placeholder='Recherche'
            />
          </InputGroup>
        </Form.Group>
      </div>
      <Table striped hover responsive className='responsive-font-small border py-5 mb-5'>
        <thead className=''>
          <tr>
            <th>Id</th>
            <th>Nom</th>
            <th>date de création</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && (
            companiesData?.map((company: any, indx: any) => (
              <tr key={indx}>
                <td className='pointer p-0 p-md-2'>{company?.id_company}</td>
                <td className='pointer p-0 p-md-2'>{company?.name}</td>
                {/* <td className='pointer p-0 p-md-2'>{company?.slug}</td> */}
                <td className='pointer p-0 p-md-2'>{(company?.created_at).slice(0,10)}</td>
                <td className='pointer p-0 p-md-2'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='transparent'
                      id='dropdown-basic'
                      className='border-0 no-chevron'
                    >
                      <b>
                        {' '}
                        <i className='ri-more-2-line'></i>
                      </b>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='end'>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedCompanyData(company)
                          handleShowUpdateCompany()
                        }}
                      >
                        <i className='ri-pencil-line'></i> Modifier
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                      
                      >
                        <i className='ri-delete-bin-2-line'></i> Supprimer
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) 
           
          }
        </tbody>
      </Table>
      {isLoading && (
        <Container fluid className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'>
          <Spinner variant="primary" /> {' '} <span className='ms-3'>Loading...</span>
        </Container>
      )}

      <ModalUpdateCompany {...modalUpdateCompanyProps} />
      <FloatingAddCompanyButton floatingAddCompanyButtonProps={floatingAddCompanyButtonProps} />
    </div>
  )
}

