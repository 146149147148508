import { statusType } from "../definitions/statusType"
import { stockageType } from "../definitions/stockageType"
import pontee from '../styles/images/pontee.png'
import calex from '../styles/images/calex.png'
import fresh from '../styles/images/fresh.png'
import freeze from '../styles/images/freeze.png'
import OrdersService from "../services/orders/OrdersService"
import moment from "moment"
import { OrderType } from "../definitions/OrderType"
import { SetStateAction } from "react"


export const _handleSelectOrders = (order: any, ordersForConnaissement: any[], setOrdersForConnaissement: React.Dispatch<React.SetStateAction<any[]>>) => {
    if (ordersForConnaissement.includes(order)) {
      setOrdersForConnaissement(ordersForConnaissement.filter((item: any) => item !== order))
    } else {
      setOrdersForConnaissement([...ordersForConnaissement, order])
    }
  }

  // Fonction qui permet de formater la date du jour (YY-mm-dd)
  export const _todayDate = () => {
    const today = new Date()

    // Obtenir l'année
    const year = today.getFullYear()

    // Obtenir le mois (ajouter 1 car les mois commencent à 0)
    const month = String(today.getMonth() + 1).padStart(2, '0')

    // Obtenir le jour du mois
    const day = String(today.getDate()).padStart(2, '0')

    // Combiner le tout dans le format souhaité
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  export const _formatDate = (date: string) => {
    const formattedDate = moment(date).format('DD/MM/YYYY');

    return formattedDate;

  }

  //Fonction concatène le numéro de plusienurs factures
  export const _numFacture = (ordersForConnaissement: any) => {
    if (ordersForConnaissement?.length > 0) {
      const concateNumFacture = ordersForConnaissement.map((order: any) => order.referenceHorsRevatua).join('|')
      return concateNumFacture
    }
  }
  //Fonction récupère l'id de plusienurs factures
  export const _idOrders = (ordersForConnaissement: any) => {
    if (ordersForConnaissement?.length > 0) {
      const concateIdFacture = ordersForConnaissement.map((order: any) => order.id)
      return concateIdFacture
    }
  }

  //concatène les produits de plusienurs factures et formatte les données
 export const _detailProduct = (ordersForConnaissement: any, versionBill: string | undefined) => {
    if (ordersForConnaissement?.length > 0) {
      const formatData = (data: any) => {
        return data.map((item: any) => {
          if (item.detail_contenant === '') {
            return {
              version: versionBill,
              nbColis: item.detail_nbColis,
              description: item.detail_description.trim(),
              codeSH: item.detail_codeSH.replace(/\./g, ''),
              codeTarif: item.detail_codeTarif,
              stockage: item.detail_stockage,
              poids: item.detail_poids,
              unitePoids: item.detail_unitePoids,
              volume: item.detail_volume,
              uniteVolume: item.detail_uniteVolume,
              montantDeclare: item.detail_montantDeclare,
              referenceExterne: item.detail_referenceExterne,
            }
          } else {
            return {
              version: versionBill,
              nbColis: item.detail_nbColis,
              description: item.detail_description.trim(),
              codeSH: item.detail_codeSH.replace(/[.\s]/g, ''),
              contenant: item.detail_contenant,
              codeTarif: item.detail_codeTarif,
              stockage: item.detail_stockage,
              poids: item.detail_poids,
              unitePoids: item.detail_unitePoids,
              volume: item.detail_volume,
              uniteVolume: item.detail_uniteVolume,
              montantDeclare: item.detail_montantDeclare,
              referenceExterne: item.detail_referenceExterne,
            }
          }
        })
      }
      const detailProducts = ordersForConnaissement.map((order: any) => 
        order.items.map((detail: any) => detail)
      );
      // Utiliser reduce pour concaténer tous les tableaux dans `detailProducts`
      const concatdetailProduct = detailProducts.reduce((acc: any[], current: any[]) => acc.concat(current), []);
      return formatData(concatdetailProduct);

    }
  }

  //Retire les produits doublons et addition les qté, les poids, les volumes et les montants
  export const _reduceTabProduct = (ordersForConnaissement: any, versionBill: string | undefined) => {
    const prod = Object?.values(
      _detailProduct(ordersForConnaissement, versionBill)?.reduce((acc: any, item: any) => {
        const key = item.description; // Regrouper par description
    
        if (!acc[key]) {
          // Initialisation du groupe s'il n'existe pas
          acc[key] = {
            ...item,
            nbColis: 0,
            poids: 0,
            volume: 0,
            montantDeclare: 0,
          };
        }
    
        // Agréger les valeurs
        acc[key].nbColis += item.nbColis;
        acc[key].poids += parseFloat(item.poids);
        acc[key].volume += item.volume;
        acc[key].montantDeclare += item.montantDeclare;
    
        return acc;
      }, {})
    )

    return prod;
  }
  //total des colis pour les factures
  export const _totalColis = (ordersForConnaissement: any) => {

    const detailProducts = _detailProduct(ordersForConnaissement,undefined )
    const total = detailProducts?.reduce((acc: any, current: any) => 
      acc + current.nbColis
    , 0);
    return total;
  }

  export const _totalVolume = (ordersForConnaissement: any) => {

    const detailProducts = _detailProduct(ordersForConnaissement, undefined)
    const total = detailProducts?.reduce((acc: any, current: any) => 
      acc + current.volume
    , 0);
    return total;
  }

  export const _thousandSeparator = (number: number, locale: string = 'fr-FR'): string => {
    return number?.toLocaleString(locale, {
      minimumFractionDigits: 0,  // Pas de décimales
    });
  }

  //Définie la couleur des tags en fonction du status
  export const _tagStatus = (status: statusType) => {
      switch (status) {

        case 'BROUILLON': return 'yellow';

        case 'DEMANDE': return 'cyan';

        case 'DEMANDE_REFUSEE': return 'red';
        
        case 'SAISIE': return 'blue';

        case 'OFFICIALISE': return 'green';

        case 'OFFICIALISE_SOUS_RESERVE': return 'orange';

        case 'MODIFIE': return 'yellow';

        case 'PRIS_EN_CHARGE': return 'orange';
        
        case 'EMBARQUE': return 'green';
        
        case 'EMBARQUEMENT_REFUSE': return 'red';
        
        case 'TRANSFERE': return 'violet';
        
        case 'ANNULE': return 'red';
        
        
        default:
          return undefined;
      }
  }

  export const _tempStockage = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return 'FRAIS'

      case 'CALE':
        return 'SEC'

      case 'CONGELE':
        return 'CONGELE'

      case 'PONTEE':
        return 'SEC'

      default:
        return undefined
    }
  }
  export const _tempStockageColor = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return 'bg-green-pale'

      case 'CALE':
        return 'bg-yellow-pale'

      case 'CONGELE':
        return 'bg-blue-pale'

      case 'PONTEE':
        return 'bg-yellow-pale'

      default:
        return undefined
    }
  }
  //Définie la couleur des tags en fonction du stockage
  export const _tagStockage = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return 'cyan'

      case 'CALE':
        return 'orange'

      case 'CONGELE':
        return 'blue'

      case 'PONTEE':
        return 'yellow'

      default:
        return undefined
    }
  }

  //Définie l'icone en fonction du stockage
  export const _stockagePics = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return fresh

      case 'CALE':
        return calex

      case 'CONGELE':
        return freeze

      case 'PONTEE':
        return pontee

      default:
        return undefined
    }

  }

  export const _transformDataToNested = (data: any) => {
    const result: any = []
    // console.log(data)
    data?.forEach((item: any) => {

        result.push({
          expediteur: {
            denomination: item.expediteur_denomination,
            telephone: item.expediteur_telephone,
            mail: item.expediteur_mail,
            numeroTahiti: item.expediteur_numeroTahiti,
          },
          
          id: item.id,
          id_connaissement: item.id_connaissement,
          id_company: item.company?.id_company ? item.company?.id_company : item?.id_company,
          numeroVoyage: item.numeroVoyage,
          paiement: item.paiement,
          ileDepart: item.ileDepart,
          ileArrivee: item.ileArrivee,
          lieuArrivee: item.lieuArrivee,
          date_creation: item.date_creation,
          date_etl: item.date_etl,
          dateFacture: item.date_facture,
          dateLivraison: item.date_livraison,
          date_modification: item.date_modification,
          numeroCommande: item.numero_commande,
          navire: item?.bateau?.replace(/Bateau /g, ''),
          // navire: item?.bateau,
          statusRevatua: item.statut_revatua,
          referenceHorsRevatua: item.referenceHorsRevatua,
          destinataire: {
            denomination: item.destinataire_denomination,
            telephone: item.destinataire_telephone,
            mail: item.destinataire_mail,
            numeroTahiti: item.destinataire_numeroTahiti,
          },
          items: item.items,
        })
    })
  
    return result
  }

  export const _getOrdersData = async (token: string, setDataOrder: any, setIsLoading: any, setErrorOrderMessage: any, itemPerPage: number) => {
    setIsLoading(true)
    try{

      let page = 1
      let allOrders: any = []
      let hasMorePages = true

      while (hasMorePages) {
        const response = await OrdersService.getOrders(token, page, itemPerPage)
        
        allOrders = [...allOrders, ..._transformDataToNested(response.data.data)]
     
        if (response.data.meta.current_page < 1) {
          page++
        } else {
          hasMorePages = false
        }
      }
      
      
      setDataOrder(allOrders)
      setIsLoading(false)
        setErrorOrderMessage({
          message: "",
          isError: false,
        })

    }catch(error: any){
        setIsLoading(false)
        setErrorOrderMessage({
          message: error?.message,
          isError: true,
        })
        console.log(error)
    }
  }

  export const _getOrdersData2 = async (
    token: string | null,
    page: number,  // Ajouter la page en paramètre
    setDataOrder: any,
    setTotalPages: any,
    setIsLoading: any,
    setErrorOrderMessage: any,
    itemPerPage: number,
  ) => {
    setIsLoading(true);
    setDataOrder([])
    try {
      const response = await OrdersService.getOrders(token, page, itemPerPage);
      setDataOrder(_transformDataToNested(response.data.data));
     
      // Nombre total de pages
      setTotalPages(response.data.meta.last_page);
      setIsLoading(false);
      setErrorOrderMessage({
        message: "",
        isError: false,
      });
    } catch (error: any) {
      setIsLoading(false);
      setErrorOrderMessage({
        message: error?.message,
        isError: true,
      });
      console.log(error);
    }
  };

  export const _dataSorter = (sortName: string, order: 'asc' | 'desc', setSortConfig: any, selectedOrder: OrderType, setSelectedOrder: React.Dispatch<SetStateAction<OrderType>>) => {
    setSortConfig({key: sortName, order})
    const sortedItems = [...(selectedOrder?.items || [])]; // Créer une copie des éléments
  
    sortedItems.sort((a: any, b: any) => {
      const aValue = a?.[sortName];
      const bValue = b?.[sortName];
  
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
  
      if (typeof aValue === 'number' || typeof bValue === 'number') {
        return order === 'asc'
          ? (aValue || 0) - (bValue || 0)
          : (bValue || 0) - (aValue || 0);
      }
  
      if (aValue && bValue) {
        return order === 'asc'
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      }
  
      return 0; // En cas de valeurs nulles ou indéfinies
    });
  
    // Mettre à jour l'état avec les éléments triés
    setSelectedOrder((prev: OrderType) => ({ ...prev, items: sortedItems }));
  };
  

  export const _handleClearCache = () => {
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    } else {
      console.log(
        "Impossible de vider le cache. Votre navigateur ne prend pas en charge cette fonctionnalité."
      );
    }
  };

  export const _handlefilteredOrder = (e: any, filteringData: any, setFilteringData: any) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
  }

  export const _getIdNavire = (navire: string, naviresData: any) => {

    if(navire){
      const result = naviresData?.filter((item: any) => item.name?.toLowerCase()?.includes(navire?.toLowerCase()))[0];
      return result?.id ? `${result.id}` : undefined
    }
    return undefined
  }

  export const _getIdIsland = (island: string, islandData: any) => {

    if(island){
      const result = islandData?.filter((item: any) => item.name?.toLowerCase()?.includes(island?.toLowerCase()))[0];
      return result?.id ? `${result.id}` : undefined
    }
    return undefined
  }
  
  export const _totalWeight = (selectedConnaissement: any) => {
    const weight = selectedConnaissement?.detailConnaissements
  ?.reduce((acc: any, product: any) => acc + product.poids, 0)
  ?.toFixed(3)
return weight;
  }

  //total des volume pour les connaissements
  export const _volumeTotal = (selectedConnaissement: any) => { 
    const volume = selectedConnaissement?.detailConnaissements
    ?.reduce((acc: any, product: any) => acc + product.volume, 0)
    ?.toFixed(3)
    return volume;
  }
    //total des colis pour les connaissements
  export const _colisTotal  = (selectedConnaissement: any) => { 
    
    const colis = selectedConnaissement?.detailConnaissements?.reduce(
    (acc: any, product: any) => acc + product.nbColis,
    0
  )
  return colis

  }

  export const _totalMontant  = (selectedConnaissement: any) => { 
    const montant = selectedConnaissement?.detailConnaissements?.reduce(
    (acc: any, product: any) => acc + product.montantDeclare,
    0
  )

  return montant;

  }

  //couleur du text affichage mobile des items des connaissements des transporteur
  export const _colorMyText = (shipName: string): string | undefined => {
    switch (shipName) {
      //bateaux tests
      case 'DPAM HOE':
        return 'info'
      // return 'remora-secondary'
      case 'DPAM TORU':
        return 'warning'

      //bataeux officiels
      case 'APETAHI EXPRESS':
        return 'remora-primary'
      case 'ARANUI 5':
        return 'primary'
      case 'AREMITI 6':
        return 'remora-secondary'
      case 'AREMITI FERRY II':
        return 'violet'
      case 'COBIA 3':
        return 'orange'
      case 'DORY':
        return 'green'
      case "HAVA'I":
        return 'yellow'
      case 'HAWAIKI NUI':
        return 'yellow'
      case 'HONU O TE HAU':
        return 'danger'
      case 'KAOHA TINI':
        return 'remora-secondary'
      case 'MAREVA NUI':
        return 'orange'
      case 'MAUPITI EXPRESS 2':
        return 'green'
      case 'NUKU HAU':
        return 'primary'
      case 'SAINT XAVIER MARIS STELLA III':
        return 'yellow'
      case 'ST X MARIS STELLA IV':
        return 'danger'
      case 'TAHITI NUI':
        return 'violet'
      case 'TAHITI NUI 8':
        return 'orange'
      case 'TAPORO IX':
        return 'green'
      case 'TAPORO VI':
        return 'yellow'
      case 'TAPORO VIII':
        return 'danger'
      case 'TE ATA O HIVA':
        return 'remora-primary'
      case 'TEREVAU':
        return 'remora-secondary'
      case 'TUHAA PAE IV':
        return 'violet'
      case "VAEARA'I":
        return 'orange'
      case 'VAITERE 2':
        return 'green'

      //Company
      case 'LOGIS':
        return 'green'
      case 'WAN IMPORT':
        return 'violet'
      case 'WAN DISTRIBUTION':
        return 'remora-primary'
      case 'CEDIS':
        return 'primary'
      case 'BEVCO':
        return 'remora-secondary'
      case 'FOODEZ':
        return 'violet'
      case 'COPA':
        return 'orange'
      case 'VERDEEN SCA':
        return 'green'
      case 'SODIMARK':
        return 'yellow'
      case 'SALAISONS DE TAHITI':
        return 'yellow'
      case 'CHP MOOREA - TOA MOOREA':
        return 'danger'
      case 'CHP RAIATEA-SC DE RAIATEA':
        return 'remora-secondary'

      default:
        // Retourne une couleur aléatoire si le nom n'est pas spécifié dans la liste
        return 'secondary'
    }
  };