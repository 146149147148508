import React from 'react'

export default function ErrorMessage({errorMessageProps}: any) {
const {isError} = errorMessageProps

  return (
    <>
     <i className='ri-error-warning-line text-danger me-2 fs-4 '></i>{isError?.message} 
    </>
  )
}
