import React from 'react'
import { Placeholder } from 'react-bootstrap'

export default function ItemTransportLoader() {
  return (
    <Placeholder as='div' animation='glow'>
      <Placeholder xs={12} as='div' className='py-1 px-2 rounded-4 mb-0 mb-2'>
        <div
          style={{ height: '35px' }}
          className='w-100 d-flex justify-content-around align-items-center'
        >
          <Placeholder xs={2} as='div' className='bg-secondary rounded  my-0' />
          <Placeholder xs={7} as='div' className='bg-secondary rounded my-0' />
          <Placeholder xs={2} as='div' className='bg-secondary rounded  my-0' />
        </div>
      </Placeholder>
    </Placeholder>
  )
}
