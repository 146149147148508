import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { PieChart } from '@rsuite/charts'
// import data from '../../data/charts/donut.json'
import DownloadCSV from '../../component/ui/DownloadCSV';
import { CSVLink } from "react-csv";

const jsonData = [
  { name: 'John', age: 28, city: 'New York' },
  { name: 'Anna', age: 22, city: 'London' },
  { name: 'Mike', age: 32, city: 'Chicago' }
];


export default function DashBoard() {
  // const { billData } = useOutletContext<any>()
  const colors: any = ['#34c3ff', '#1464ac']
  // const chartData: any = data
  
  const dataz = "Name,Age,Profession\nJohn Doe,30,Developer\nJane Smith,25,Designer";
  const [donutData, setDonutData] = React.useState<any>([])
  const status = [
    'BROUILLON',
    'DEAMANDE',
    'DEMANDE_REFUSEE',
    'SAISIE',
    'OFFICIALISE',
    'OFFICIALISE_SOUS_RESERVE',
    'MODIFIE',
    'PRIS_EN_CHARGE',
    'EMBARQUE',
    'EMBARQUEMENT_REFUSE',
    'TRANSFERE',
    'ANNULE',
  ]

  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" }
  ];
  
  const data = [
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  ];

  //https://rsuitejs.com/resources/extensions/

  // React.useEffect(() => {
  //   statusChartData()
  // }, [billData])

  console.log(donutData)
  // const statusChartData = () => {
  //   // const brouillon = billData?.filter(
  //   //   (item: any) => item?.dernierEtat.evenementConnaissement === "BROUILLON"
  //   // )
  //   // const demande = billData?.filter(
  //   //   (item: any) => item?.dernierEtat.evenementConnaissement === "DEMANDE"
  //   // )

  //   // setDonutData([["BROUILLON", brouillon?.length], ["DEMANDE", demande?.length]])
  //   const donutData = status.map((etat) => {
  //     const filteredData = billData?.filter(
  //       (item: any) => item?.dernierEtat.evenementConnaissement === etat
  //     )
   
  //       return [etat, filteredData?.length || 0];
      
  //   }).filter(([, count]) => count > 0);
  //   setDonutData(donutData);
  // }

  

  return (
    <>
      <div>
        <h1>Download CSV Example</h1>

        <CSVLink data={data} headers={headers}>
          Download me
        </CSVLink>

        <DownloadCSV jsonData={jsonData} fileName='infos' />
      </div>

      <div className='p-3'>
        <PieChart name='Donut' data={donutData} donut colorBy={colors} />
      </div>
    </>
  )
}
