import React from 'react'
import PermissionTable from '../../component/permission/PermissionTable';
import { Button, Container } from 'react-bootstrap';
import data from '../../data/permissions/permissions.json'

export default function PermissionManager() {

  const [permissionData, setPermissionData] = React.useState<any>(data)
  const tablePermissionProps = {permissionData, setPermissionData}

  return (
    <div className='p-3'>
      <Container className='d-flex justify-content-between mb-3'>


      <h3 className='text-secondary'>Gestion des permissions</h3> 
      <Button variant="primary">Enregistrer</Button>
      </Container>
      <PermissionTable tablePermissionProps={tablePermissionProps} />
    </div>
  )
}
