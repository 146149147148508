import axios from "axios";

type userData = {
    username: string,
    password: string
}

const API_URL_REVATUA = process.env.REACT_APP_END_POINT
const AUTH_URL_REVATUA = process.env.REACT_APP_AUTH_URL_TEST
const USERNAME_REVATUA = process.env.REACT_APP_API_USERNAME_TEST
const PASSWORD_REVATUA = process.env.REACT_APP_API_PASSWORD_TEST
const CLIENT_SECRET_REVATUA = process.env.REACT_APP_API_CLIENT_SECRET_TEST

const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT

class AuthService {
    loginRevatua(userName: string, password: string) {
        return axios.post(API_URL_REVATUA + "oauth2/token")
    
    }

    authRevatua() {
        const authData = {
            'grant_type': 'client_credentials',
            'username': USERNAME_REVATUA,
            'password': PASSWORD_REVATUA,
            'client_id': 'revatua',
            'client_secret': CLIENT_SECRET_REVATUA,
            'access_token' : 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ5S05Tc2hfR0JNQ3V0Ui1RR1dDQmlKRzF5UVQwRlltMzg5MlhHV0dSTWhjIn0.eyJleHAiOjE3MjYyOTk2ODMsImlhdCI6MTcyNjI3NDQ4MywianRpIjoiMmY0ZDRlM2QtODU2YS00ZjAzLTgyY2YtYTAwMDBiOWJlNjYzIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLnNhbmRib3guYXdzLmdvdi5wZi9hdXRoL3JlYWxtcy9kcGFtIiwic3ViIjoiOGVjMmFiNTItNjVjNi00ZDc4LWEwZmUtMmI4Mjc3ODExYWZjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoicmV2YXR1YSIsInNlc3Npb25fc3RhdGUiOiIwM2YxN2M3MS1lYTE4LTQyMmEtOGFiZi1iNDcwNGEzYjkwYWIiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly9sb2NhbGhvc3Q6NDIwMCIsImh0dHBzOi8vdGVzdC5yZXZhdHVhLmdvdi5wZiIsImh0dHBzOi8vdGVzdC5kcGFtLnJldmF0dWEuZ292LnBmIl0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsInNpZCI6IjAzZjE3YzcxLWVhMTgtNDIyYS04YWJmLWI0NzA0YTNiOTBhYiIsInNjb3BlX3JldmF0dWEiOiJSRVZBVFVBL2FwaS5jaGFyZ2V1ciIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkFQSSBMT0dJUyIsImlkX3JldmF0dWEiOiI4ZWMyYWI1Mi02NWM2LTRkNzgtYTBmZS0yYjgyNzc4MTFhZmMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJtYXR0aGlldS5ib3V0aGV0QGx3YW5lLmNvbSIsImdpdmVuX25hbWUiOiJBUEkiLCJmYW1pbHlfbmFtZSI6IkxPR0lTIiwiZW1haWwiOiJtYXR0aGlldS5ib3V0aGV0QGx3YW5lLmNvbSJ9.u9kLxgKoBaUx7C4sAr--2fG6em3wucY9alAh9ZdcsOwqy9UcQKpkNBB4orQnJBUFhqbGcis0nbTNuBtGmlgLZFUibJRKHK-i3qKmP-W4ToPsaMHAr23WJGV20p86P3bbruXkBzZ5dxDcMdQKyPALnxA3GaRgFRCJ0-aD_GctHV-Eb-6yjndhVv7Qrwo6rymAUhRL_RKUCHjnXIp2vK4bAKHfBoquxQyyv-WxN46rLb0oIlXD0_ev2DaCLs66X6XbY8olPAj0QfwI8jbhUz6ZhZCRTN3iG0m2dfITHz6HtJyFl1EwKkhuutAEmLjNtTv2Snz8yH8elH4qLsk7x2NBQQ'
          }
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: AUTH_URL_REVATUA,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: authData,
        }
        return axios.request(config)
      }


    loginTotara(data: userData) {
        return axios.post(API_URL_TOTARA + '/api/v1/login', 
        data
        )
    }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;