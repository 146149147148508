export default function TableTitleTransporter({isTransporter}: any) {

    return (
      <thead>
        <tr>
          <th>
            N°<span className='d-none d-sm-inline'>Connaiss...</span>
          </th>
          <th>Société</th>
          <th>Client</th>
          <th>Navire</th>
          <th>Date départ</th>
          <th>Action</th>
        </tr>
      </thead>
    )
  }
  