export const _errorForgotPass = (error: string | undefined) => {
    switch (error) {

        case 'validation.exists': return "Ce compte n'existe pas, vérifiez votre email";
        
        case 'passwords.token': return "Token déjà utilisé ou expiré. Cliquez de nouveau sur 'mot de passe oublié'.";
       
        
        
        default:
          return undefined;
      }
}