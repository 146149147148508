import React from 'react'
import { Container, Accordion } from 'react-bootstrap'
import CardPermission from './CardPermission';

export default function PermissionTable({tablePermissionProps}: any) {
  const {permissionData, setPermissionData} = tablePermissionProps;

  return (
    <Container>
      <Accordion defaultActiveKey='0'>
        {permissionData?.map((profiles: any, indexProfile: number) => {
          const cardPermissionProps = {
            profiles,
            indexProfile,
            permissionData,
            setPermissionData,
          }
          return (
            <Accordion.Item key={indexProfile} eventKey={`${indexProfile}`}>
              <Accordion.Header>
                <b>{profiles.profile}</b>
              </Accordion.Header>
              <Accordion.Body>
                <CardPermission cardPermissionProps={cardPermissionProps} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </Container>
  )
}
