import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT

class NaviresService {
    getNaviresList(token: string) {
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/public/navires`,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + token,
            }
          }
          return axios.request(config)
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NaviresService();