import { WarningTwoTone } from '@ant-design/icons'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { _colorMyText } from '../../utils/functions'

export default function ItemTransporter({itemTransporterProps}: any) {
    const {connaissement, setSelectedConnaissement, handleShowDetailConnaiss, borderColor} = itemTransporterProps

    const isDangerous = connaissement.detailConnaissements.some(
        (detail: any) => detail.matiereDangereuse === true
      )
      const isFragile = connaissement.detailConnaissements.some(
        (detail: any) => detail.fragile === true
      )
  return (
    <Container
      fluid
      className={`border-${borderColor}-2 rounded-4 mb-2 py-1 px-2`}
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShowDetailConnaiss()
      }}
    >
      <Row className=''>
        <Col xs={2} className={`m-auto text-start pe-0 responsive-font-small text-${_colorMyText(connaissement?.expediteur?.denomination)}`}>
          <b>{connaissement?.expediteur?.denomination}</b>
        </Col>
        <Col xs={8} className='m-auto'>
          <Row>
            <Col xs={12} className='m-auto responsive-font-small'>
              {connaissement?.destinataire?.denomination}
            </Col>
            <Col
              xs={isDangerous || isFragile ? 12 : 5}
              className='m-auto responsive-font-small'
            >
              <b className={`text-${_colorMyText(connaissement?.voyage?.nomNavire)}`}>{connaissement?.voyage?.nomNavire}</b>
            </Col>
            <Col
              xs={isDangerous || isFragile ? 8 : 7}
              className='m-auto responsive-font-small'
            >
              <b>{connaissement?.numero}</b>
            </Col>
            <Col xs={4} className='m-auto'>
              {isDangerous && (
                <>
                  <WarningTwoTone twoToneColor='#ee4848' />{' '}
                </>
              )}
              {isFragile && <WarningTwoTone twoToneColor='#fa8900' />}
            </Col>
          </Row>
        </Col>
        <Col xs={2} className='m-auto text-end ps-0 responsive-font-small'>
          {' '}
          {new Date(connaissement?.voyage?.dateDepart).toLocaleDateString('fr-FR', {
            timeZone: 'UTC',
          })}
        </Col>
      </Row>
    </Container>
  )
}
