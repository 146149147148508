import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import SearchTest from '../../../pages/private/SearchTest'
import OrdersService from '../../../services/orders/OrdersService'
import userStore from '../../../stores/userStore'
import {_dataSorter, _tagStatus } from '../../../utils/functions'
import { Tag } from 'rsuite'
import { _handleAddproduct, _handleUpdateProduct } from '../../../utils/api/totaraApi'
import { debounce } from 'lodash'
import { errorType } from '../../../definitions/errorType'
import { usePDF, Margin } from 'react-to-pdf'
import { DetailOrderModalType, SearchPlanningModalType } from '../../../definitions/ComponentType'
import codeSH2023 from '../../../data/codeSH/codeSH2024.json'
import HeaderDetail from '../../billOfLading/detail/HeaderDetail'
import TableDetail from '../../billOfLading/detail/TableDetail'
import FooterDetail from '../../billOfLading/detail/FooterDetail'
import PrintFooterDetail from '../../billOfLading/detail/PrintFooterDetail'
import PdfViewer from '../../pdf/PdfViewer'
import { _getPDFConnaissementByEvenement } from '../../../utils/api/apiControlerFunctions'
import { stockageData } from '../../../data/commandes/divers'
import { AddProductModal, UpdateProductModal } from './OrderProduct'


// component who display the planning of diffrents ships
export function SearchPlanningModal({ SearchPlanningModalProps }: {SearchPlanningModalProps: SearchPlanningModalType}) {
  const { showSearchPlanning, handleCloseSearchPlanning, searchPlanningProps } =
    SearchPlanningModalProps

  return (
    <Modal size='lg' show={showSearchPlanning} onHide={handleCloseSearchPlanning}>
      <SearchTest searchPlanningProps={searchPlanningProps} />
      <Modal.Footer className='border-0 sticky-bottom justify-content-start'>
        <Button variant='secondary' className='' onClick={handleCloseSearchPlanning}>
        <i className='ri-close-line me-2' ></i>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// Display order (bill) detail
export function DetailOrderModal({ detailOrderModalProps }: {detailOrderModalProps: DetailOrderModalType}) {
 
  const {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setInfoOrder,
    toggleShowInfo,
    setOrdersForConnaissement,
  } = detailOrderModalProps

  const dataStore = userStore((state: any) => state)
  const [validated, setValidated] = React.useState<boolean>(true);
  const [showUpdateProductModal, setShowUpdateProductModal] = React.useState<boolean>(false)
  const [sortConfig, setSortConfig] = React?.useState<{ key: string; order: 'asc' | 'desc' } | null>(null);

  const [isDesc, setIsDesc] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })

  const [sortedProductByCodeSH, setSortedProductByCodeSH] = React.useState<any>()
  const [selectedProduct, setSelectedProduct] = React.useState<any>()
  const [filteringData, setFilteringData] = React.useState<any>({
    detail_referenceExterne: '',
    detail_contenant: '',
    detail_description: '',
    detail_nbColis: '',
    detail_poids: '',
    detail_stockage: '',
    detail_codeTarif: '',
    detail_codeSH: '',
  })

  const codeSH = codeSH2023
  const [searchCodeSh, setSearchCodeSh] = React.useState<any>()
  const [filteredCodeSh, setFilteredCodeSh] = React.useState<any>([])

  const handleCloseUpdateProductModal = () => {
    setShowUpdateProductModal(false)
    setSelectedProduct({})
  }
  
  const handleShowUpdateProductModal = () => {
    setIsError({
      error: false,
      message: '',
    })
    setShowUpdateProductModal(true)
  }

  const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false)

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false)
  }
  //Bloquer : Ajout produit à une commande existante
  // const handleShowAddProductModal = () => {
  //   setShowAddProductModal(true)
  //   setSelectedProduct({})
  // }

  React.useEffect(() => {
    if(selectedProduct?.detail_codeSH?.length > 2){

      const findingCodeSh: any = codeSH?.filter((sh: any) => {
        return sh?.code?.toString()?.includes(selectedProduct?.detail_codeSH)
        || (sh?.description?.toString()?.toLowerCase() ?? "")?.includes((selectedProduct?.detail_codeSH).toLowerCase() ?? "")})
        setFilteredCodeSh(findingCodeSh)
      }
      if(selectedProduct?.detail_codeSH?.length === 0){
      setFilteredCodeSh([])
      }
  }, [selectedProduct?.detail_codeSH])
  

  React.useEffect(() => {
    setSortedProductByCodeSH( selectedOrder?.items)
 
  }, [selectedOrder])


  //Editer une ligne de commande
  const handleSaveProductUpdates = (e: any) => {
    e.preventDefault()
    const form = e.currentTarget
    console.log(form.checkValidity())
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      const orderData = {
        detail_nbColis: selectedProduct.detail_nbColis,
        detail_contenant: selectedProduct.detail_contenant,
        detail_description: selectedProduct.detail_description,
        detail_codeSH: selectedProduct.detail_codeSH,
        detail_codeTarif: selectedProduct.detail_codeTarif,
        detail_stockage: selectedProduct.detail_stockage,
        detail_poids: selectedProduct.detail_poids,
        detail_referenceExterne: selectedProduct.detail_referenceExterne,
      }
      // Tableau d'origine
      const updatedProducts = selectedOrder.items.map((product: any) => {
        // Si l'ID du produit correspond à celui sélectionné, on le met à jour
        if (product?.detail_referenceExterne === selectedProduct?.detail_referenceExterne) {
          return {
            ...product,
            detail_nbColis: selectedProduct.detail_nbColis,
            detail_contenant: selectedProduct.detail_contenant,
            detail_description: selectedProduct.detail_description,
            detail_codeSH: selectedProduct.detail_codeSH,
            detail_codeTarif: selectedProduct.detail_codeTarif,
            detail_stockage: selectedProduct.detail_stockage,
            detail_poids: selectedProduct.detail_poids,
            detail_referenceExterne: selectedProduct.detail_referenceExterne, // On applique les changements
          }
        }
        return product // On retourne les autres produits inchangés
      })
      try {
        // Mise à jour de l'état selectedOrder avec les produits mis à jour
        // setSelectedOrder((prevOrder: any) => ({
        //   ...prevOrder,
        //   items: updatedProducts,
        // }))

        const updatedOrder = dataOrder?.map((order: any, indx: number) => {
          if (order.id === selectedOrder?.id) {
            return {
              ...order,
              items: updatedProducts,
            }
          }
          return order
        })

        // setDataOrder(updatedOrder)

        //Applique les changement en base de donnée
        _handleUpdateProduct(
          dataStore?.token,
          orderData,
          selectedProduct.id,
          setIsError,
          handleCloseUpdateProductModal,
          setSelectedOrder,
          updatedProducts,
          setDataOrder,
          updatedOrder
        )
        setOrdersForConnaissement([])
      } catch (error) {
        console.log(error)
      }
      // _getOrdersData2(dataStore?.token,
      //   currentPage,
      //   setDataOrder,
      //   setTotalPages,
      //   setIsLoading,
      //   setErrorOrderMessage)
      // Fermer le modal
    }
  }

  //Ajouter un produit à une commande existante
  const handleSaveProductAdd = (e: any) => {
    e.preventDefault()
    const orderData = {
      id_order: selectedOrder.id,
      detail_nbColis: selectedProduct.detail_nbColis,
      detail_contenant: selectedProduct.detail_contenant,
      detail_description: selectedProduct.detail_description,
      detail_codeSH: selectedProduct.detail_codeSH,
      detail_codeTarif: selectedProduct.detail_codeTarif,
      detail_stockage: selectedProduct.detail_stockage,
      detail_poids: selectedProduct.detail_poids,
      detail_unitePoids: 'KILO',
      detail_referenceExterne: selectedProduct.detail_referenceExterne,
    }

    const newTab = [...selectedOrder.items, orderData]

    setSelectedOrder((prevOrder: any) => ({
      ...prevOrder,
      items: newTab,
    }))

    _handleAddproduct(dataStore?.token, orderData)
    handleCloseAddProductModal()
  }

  //Fitrer detail produit

  const handlefilteredProduct = (e: any) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
    debouncedFilter(updatedFilteringData)
  }

  const debouncedFilter = debounce((updatedFilteringData) => {
    filteredProduct(dataStore.token, updatedFilteringData, selectedOrder?.id)
  }, 500) // 300ms delay

  const filteredProduct = async (token: any, filteringData: any, id: number|null) => {
    const isEmpty =
      filteringData?.detail_codeSH === '' &&
      filteringData?.detail_contenant === '' &&
      filteringData?.detail_codeTarif === '' &&
      filteringData?.detail_description === '' &&
      filteringData?.detail_nbColis === '' &&
      filteringData?.detail_poids === '' &&
      filteringData?.detail_referenceExterne === '' &&
      filteringData?.detail_stockage === '' &&
      filteringData?.detail_unitePoids === '' &&
      filteringData?.id === '' &&
      filteringData?.id_order === ''
    try {
      const response = await OrdersService.filteredItem(token, filteringData, id)
      console.log(response)
      if (response.data.data.length > 0 && !isEmpty) {
        const dataz = response.data.data?.filter((prod: any) => {
          return (
            prod.detail_codeSH,
            prod.detail_contenant,
            prod.detail_codeTarif,
            prod.detail_description,
            prod.detail_nbColis,
            prod.detail_poids,
            prod.detail_referenceExterne,
            prod.detail_stockage,
            prod.detail_unitePoids,
            prod.id,
            prod.id_order
          )
        })
        setSortedProductByCodeSH(dataz)
      } else {
        setSortedProductByCodeSH(
          selectedOrder?.items?.sort((a: any, b: any) => a?.codeSH?.localeCompare(b?.codeSH))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addProductModalProps = {selectedProduct, setSelectedProduct, showAddProductModal, handleCloseAddProductModal, handleSaveProductAdd, stockageData}
  const updateProductProps = {
    selectedProduct,
    setSelectedProduct,
    showUpdateProductModal,
    handleCloseUpdateProductModal,
    validated,
    setValidated,
    handleSaveProductUpdates,
    filteredCodeSh,
    setSearchCodeSh,
    setFilteredCodeSh,
    isError
  }

  return (
    <>
      <Modal size='xl' show={show} onHide={() => {handleClose()
        setSortConfig(null)
      }} className='px-0'>
        <Modal.Header className='text-center'>
          <Container fluid>
            <Row>
              <Col xs={12} className='m-auto responsive-font-medium  mb-3'>
                {selectedOrder?.destinataire?.denomination}
              </Col>

              <Col xs={4} className='m-auto text-start responsive-font-small'>
                <b>N° Facture : </b>
                {selectedOrder?.referenceHorsRevatua}
              </Col>
              <Col xs={4} className='m-auto responsive-font-medium mb-3'>
                <Tag color={_tagStatus(selectedOrder?.statusRevatua)} className='mb-3'>
                  {selectedOrder?.statusRevatua}
                </Tag>
                <div>
                  <b>Paiement : </b>
                  {selectedOrder?.paiement}
                </div>
              </Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'>
                <b> Date : </b> {selectedOrder?.date_creation}
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Table striped hover responsive className='border '>
              <thead className='responsive-font-medium font-75'>
                <tr>
                  <th className='d-table-flex justify-content-around'>
                    Réf.
                    <span className='pointer'>
                      <i
                        className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_referenceExterne') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_referenceExterne', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                        className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_referenceExterne') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_referenceExterne', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th className='d-table-flex justify-content-around flex-row'>
                    Contenant
                    <span className='pointer'>
                      <i
                        className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_contenant') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_contenant', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                        className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_contenant') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_contenant', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th style={{ width: '256px' }}>
                    Désignation
                    <span className='pointer'>
                    <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_description') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_description', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                    ></i>{' '}
                    <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_description') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_description', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                    ></i>
                    </span>
                  </th>
                  <th>
                    Nb Colis
                    <span className='pointer'>
                      <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_nbColis') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_nbColis', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_nbColis') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_nbColis', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th className='text-end'>
                    Poids(Kg)
                    <span className='pointer'>
                      <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_poids') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_poids', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_poids') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_poids', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th>
                    Stockage
                    <span className='pointer'>
                      <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_stockage') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_stockage', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_stockage') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_stockage', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th>
                    C.Tarif
                    <span className='pointer'>
                      <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_codeTarif') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_codeTarif', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_codeTarif') ? 'text-remora-secondary' : 'text-dark'}`}
                        onClick={() => _dataSorter('detail_codeTarif', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th>
                    C.SH
                    <span className='pointer'>
                      <i
                      className={`ri-arrow-up-s-fill ${(sortConfig?.order === 'asc' && sortConfig?.key === 'detail_codeSH') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_codeSH', 'asc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>{' '}
                      <i
                      className={`ri-arrow-down-s-fill ${(sortConfig?.order === 'desc' && sortConfig?.key === 'detail_codeSH') ? 'text-remora-secondary' : 'text-dark'}`}
                      onClick={() => _dataSorter('detail_codeSH', 'desc', setSortConfig, selectedOrder, setSelectedOrder)}
                      ></i>
                    </span>
                  </th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <thead className='responsive-font-medium border-top-0'>
                <tr>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Référence'
                      name='detail_referenceExterne'
                      value={filteringData.detail_referenceExterne || undefined}
                      onChange={(e) => {
                        const detail_referenceExterne = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_referenceExterne: detail_referenceExterne,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>

                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Contenant'
                      name='detail_contenant'
                      value={filteringData.detail_contenant || undefined}
                      onChange={(e) => {
                        const detail_contenant = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_contenant: detail_contenant,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Désignation'
                      name='detail_description'
                      value={filteringData.detail_description || ''}
                      onChange={handlefilteredProduct}
                    />
                  </th>
                  <th>
                    {' '}
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='nbColis'
                      name='detail_nbColis'
                      value={filteringData.detail_nbColis || undefined}
                      onChange={(e) => {
                        const detail_nbColis = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_nbColis: detail_nbColis,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th className='text-end'>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Poids'
                      name='detail_poids'
                      value={filteringData.detail_poids || undefined}
                      onChange={(e) => {
                        const detail_poids = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_poids: detail_poids,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Stockage'
                      name='detail_stockage'
                      value={filteringData.detail_stockage || ''}
                      onChange={(e) => {
                        const detail_stockage = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_stockage: detail_stockage,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.tarif'
                      name='detail_codeTarif'
                      value={filteringData.detail_codeTarif || ''}
                      onChange={(e) => {
                        const detail_codeTarif = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeTarif: detail_codeTarif,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.SH'
                      name='detail_codeSH'
                      value={filteringData.detail_codeSH || ''}
                      onChange={(e) => {
                        const detail_codeSH = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeSH: detail_codeSH,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>

                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody className='responsive-font-small'>
                {sortedProductByCodeSH?.map((product: any, indexProd: number) => {
                  const isMiissingData =
                    product?.detail_poids <= 0 ||
                    product?.detail_poids === undefined ||
                    product?.detail_stockage === '' ||
                    product?.detail_codeTarif === '' ||
                    product?.detail_codeSH === ''
                  return (
                    <tr key={indexProd}>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_referenceExterne}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_contenant}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_description}
                      </td>
                      <td className={`text-end text-${isMiissingData && 'danger'}`}>
                        {product?.detail_nbColis}
                      </td>
                      <td className={`text-end text-${isMiissingData && 'danger'}`}>
                        {product?.detail_poids}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_stockage}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_codeTarif}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_codeSH}
                      </td>
                      <td
                        className='text-center pointer'
                        onClick={() => {
                          if (
                            selectedOrder?.statusRevatua === 'A_PLANIFIER' ||
                            selectedOrder?.statusRevatua === 'BROUILLON'
                          ) {
                            setSelectedProduct(product)
                            handleShowUpdateProductModal()
                          } else {
                            setInfoOrder(
                              `Les commandes qui sont au statut ${selectedOrder?.statusRevatua} ne sont pas modifiables`
                            )
                            toggleShowInfo()
                          }
                        }}
                      >
                        <i className='ri-pencil-line fs-5'></i>{' '}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer className='sticky-bottom border-0'>
          <Button
            variant='secondary'
            onClick={() => {
              handleClose()
              setSortConfig(null)
              setFilteringData({
                detail_description: '',
                detail_contenant: '',
                detail_nbColis: '',
                detail_referenceExterne: '',
                detail_poids: '',
                detail_stockage: '',
                detail_codeTarif: '',
                detail_codeSH: '',
              })
            }}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Product Modal bloqué*/}
      <AddProductModal addProductModalProps={addProductModalProps} />

      {/* Update product Modal */}
      <UpdateProductModal updateProductProps={updateProductProps} />
    
    </>
  )
}



// Display bill of lading (connaissement) detail
export function DetailConnaissementModal({ detailOrderModalProps }: any) {

  const {
    showDetailConnaiss,
    selectedConnaissement,
    handleCloseDetailConnaiss,
    handlePrint,
    printRef,
  } = detailOrderModalProps

  const dataStore = userStore((state: any) => state)

  const [pdfData, setPdfData] = React.useState<any>()

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${
      selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE' && 'Aperçu-'
    }${
      selectedConnaissement?.numero ? selectedConnaissement?.numero : selectedConnaissement?.id
    }.pdf`,
    page: { margin: Margin.MEDIUM },
  })

  React.useEffect(() => {
  if (selectedConnaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE' && showDetailConnaiss) {
    _getPDFConnaissementByEvenement(
      dataStore.access_token,
      selectedConnaissement?.id,
      selectedConnaissement?.dernierEtat?.id,
      setPdfData
    )
  }
    return () => {
      if (pdfData) URL.revokeObjectURL(pdfData);
    };
  }, [selectedConnaissement?.id, selectedConnaissement?.dernierEtat?.evenementConnaissement]);


  const footerDetailProps = {selectedConnaissement, toPDF, setPdfData, handlePrint, handleCloseDetailConnaiss}


  return (
    <Modal
      size='lg'
      fullscreen='lg-down'
      show={showDetailConnaiss}
      onHide={handleCloseDetailConnaiss}
      className='px-0'
    >
      <Modal.Body
        ref={printRef}
        className={`p-1 p-sm-4 text-center border-bottom-0 pdf-container ${
          selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE'
            ? 'printer'
            : ''
        }`}
      >
        <div
          ref={targetRef}
          className={` ${
            selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE'
              ? 'pdf-bg'
              : ''
          }`}
        >
          {pdfData &&
          selectedConnaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE' ? (
            <>
              <PdfViewer pdfUrl={pdfData} />
            </>
          ) : (
            <>
              <HeaderDetail selectedConnaissement={selectedConnaissement} />

              <TableDetail selectedConnaissement={selectedConnaissement} />

              <PrintFooterDetail selectedConnaissement={selectedConnaissement} />
            </>
          )}
        </div>
      </Modal.Body>
      <FooterDetail footerDetailProps={footerDetailProps} />
    </Modal>
  )
}

