import React from 'react'
import { Form, Table, Card } from'react-bootstrap'

export default function CardPermission({cardPermissionProps}: any) {
  const { profiles, indexProfile, permissionData, setPermissionData } = cardPermissionProps

  const handleCheckboxChange = (
    profileIndex: number,
    permissionIndex: number,
    action: string
  ) => {
    const updatedData = [...permissionData]
    const permission = updatedData[profileIndex].permissions[permissionIndex]
    permission[action] = !permission[action]
    setPermissionData(updatedData)
  }

  return (
    <Card className='my-3 p-3'>
      <Table responsive hover>
        <thead className=''>
          <tr>
            <th>Ressource</th>
            <th className='text-center'>Voir (GET)</th>
            <th className='text-center'>Modifier (PUT)</th>
            <th className='text-center'>Ajouter (POST)</th>
            <th className='text-center'>Supprimer (DELETE)</th>
          </tr>
        </thead>
        <tbody>
          {profiles?.permissions?.map((permission: any, indxPermission: number) => (
            <tr key={indxPermission}>
              <td className='pointer p-0 p-md-2'>{permission.ressources}</td>

              <td className='pointer p-0 p-md-2 text-center'>
                <Form.Check
                  type='checkbox'
                  id={`${profiles.profile}-${permission.ressources}-${Math.random()}}`}
                  checked={permission.get}
                  onChange={() => handleCheckboxChange(indexProfile, indxPermission, 'get')}
                />
              </td>
              <td className='pointer p-0 p-md-2 text-center'>
                <Form.Check
                  type='checkbox'
                  id={`${profiles.profile}-${permission.ressources}-${Math.random()}}`}
                  checked={permission.put}
                  onChange={() => handleCheckboxChange(indexProfile, indxPermission, 'put')}
                />
              </td>
              <td className='pointer p-0 p-md-2 text-center'>
                <Form.Check
                  type='checkbox'
                  id={`${profiles.profile}-${permission.ressources}-${Math.random()}}`}
                  checked={permission.post}
                  onChange={() => handleCheckboxChange(indexProfile, indxPermission, 'post')}
                />
              </td>
              <td className='pointer p-0 p-md-2 text-center'>
                <Form.Check
                  type='checkbox'
                  id={`${profiles.profile}-${permission.ressources}-${Math.random()}}`}
                  checked={permission.delete}
                  onChange={() => handleCheckboxChange(indexProfile, indxPermission, 'delete')}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
