import React from 'react'

export default function PrintFooterDetail({ selectedConnaissement }: any) {
  const dateLastEvent =
    new Date(
      selectedConnaissement?.dernierEtat?.dateEvenement.slice(0, 10)
    ).toLocaleDateString('fr-FR', {
      timeZone: 'UTC',
    }) +
    ' ' +
    selectedConnaissement?.dernierEtat?.dateEvenement.slice(11, 19)
  return (
    <div className='footer-connaissement'>
      <div className='printermulti'>
        REVATUA - Le {dateLastEvent} édition du connaissement n°
        {selectedConnaissement?.numero}
      </div>
      {/* <span className='page-number'></span> */}
    </div>
  )
}
