import React from 'react'
import {
  Form,
  Table,
} from 'react-bootstrap'
import {
  ToastCurrentTrip,
  ToastInfo,
  ToastSendedBrouillon,
} from '../../component/ui/Toast/Toastes'
import { DetailOrderModal, SearchPlanningModal } from '../../component/ui/Modal/Modals'
import userStore, { UserState } from '../../stores/userStore'
import { useNavigate, useOutletContext } from 'react-router-dom'
import {
  _getOrdersData2,
  _transformDataToNested,
} from '../../utils/functions'
import { OrderType } from '../../definitions/OrderType'
import OrdersService from '../../services/orders/OrdersService'
import PaginationComponent from '../../component/ui/PaginationComponent'
import OrderTableTitle from '../../component/orders/OrderTableTitle'
import OrderFilter from '../../component/orders/OrderFilter'
import PlanningButton from '../../component/orders/PlanningButton'
import ItemsLimiter from '../../component/billOfLading/ItemsLimiter'
import {
  DetailOrderModalType,
  filteringDataType,
  OrderFilterType,
  PlanningButtonType,
  SearchPlanningModalType,
  SearchPlanningType,
} from '../../definitions/ComponentType'
import NaviresService from '../../services/navires/NaviresService'
import { _refreshToken } from '../../utils/api/apiControlerFunctions'
import OrderBodyTable from '../../component/orders/OrderBodyTable'

export default function Order() {
  const { naviresData } = useOutletContext<any>()

  ///////////
  //store Data
  /////////////
  const dataStore = userStore((state: UserState) => state)
  const navigate = useNavigate()

  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name === 'transporteur'

  //////////////
  //State
  /////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  const [errorOrderMessage, setErrorOrderMessage] = React.useState<{
    error: boolean
    message: string
  }>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>('')

  const [dataOrder, setDataOrder] = React.useState<OrderType[]>([])
  const [selectedOrder, setSelectedOrder] = React.useState<OrderType>({} as OrderType)
  const evnt = undefined
  const [filteringData, setFilteringData] = React.useState<filteringDataType>({
    date_facture: '',
    referenceHorsRevatua: '',
    destinataire_denomination: '',
    numeroVoyage: '',
    statut_revatua: '',
    bateau: '',
    ileArrivee: '',
    stockage: '',
  })

  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [itemPerPage, setItemPerPage] = React.useState<number>(20)

  // const [naviresData, setNaviresData] = React.useState<any>()

  const [ordersForConnaissement, setOrdersForConnaissement] = React.useState<OrderType[]>([])
  // Toastes
  const [showA, setShowA] = React.useState<boolean>(false)
  const toggleShowA = () => setShowA(!showA)

  const [showBrouillon, setShowBrouillon] = React.useState<boolean>(false)
  const toggleShowBrouillon = () => setShowBrouillon(!showBrouillon)

  const [showErrorOrder, setShowErrorOrder] = React.useState<boolean>(false)
  const toggleShowErrorOrder = () => setShowErrorOrder(!showErrorOrder)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

  //Modals
  const [show, setShow] = React.useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [showSearchPlanning, setShowSearchPlanning] = React.useState(false)

  const handleCloseSearchPlanning = () => setShowSearchPlanning(false)
  const handleShowSearchPlanning = () => {
    if (ordersForConnaissement.length > 0) {
      setShowSearchPlanning(true)
    } else {
      toggleShowInfo()
      setInfoOrder('Veuillez sélectionner au moins une Facture')
    }
  }

  React.useEffect(() => {
    if (!dataStore?.token || dataStore?.token === undefined) {
      navigate('/connexion')
    }

    if (isTransporter) {
      navigate('/connaissements-demande')
    }
  }, [])

  React.useEffect(() => {
    if (dataStore.token && dataStore.token !== '') {
      _getOrdersData2(
        dataStore?.token,
        currentPage,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage,
        itemPerPage
      )
    }
  }, [dataStore.token])

  React.useEffect(() => {
    if (dataStore.token && dataStore.token !== '') {
      if (isFiltering) {
        filteredOrders(
          evnt,
          dataStore?.token,
          filteringData,
          currentPage,
          itemPerPage,
          totalPages
        )
      } else {
        _getOrdersData2(
          dataStore?.token,
          currentPage,
          setDataOrder,
          setTotalPages,
          setIsLoading,
          setErrorOrderMessage,
          itemPerPage
        )
      }
    }
  }, [itemPerPage])

 


  //Gère les factures selectionnées
  const handleSelectOrders = (order: OrderType) => {
    // setOrdersForConnaissement((prevOrders: OrderType[]) => {
    //   if (prevOrders.includes(order)) {
    //     return prevOrders.filter((item) => item !== order)
    //   } else {
    //     return [...prevOrders, order]
    //   }
    // })
    setOrdersForConnaissement((prevOrders) => {
      const isSelected = prevOrders.some((selected) => selected.id === order.id);
      return isSelected
        ? prevOrders.filter((selected) => selected.id !== order.id)
        : [...prevOrders, order];
    });
  }

  //Gère la selection de toutes les factures
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validOrders = dataOrder?.filter((order: OrderType) => {
        const isDifferentDestinataire =
          (ordersForConnaissement?.length > 0 &&
            ordersForConnaissement[0]?.destinataire?.denomination !==
              order?.destinataire?.denomination) ||
          (order?.statusRevatua !== 'A_PLANIFIER' && order?.statusRevatua !== 'BROUILLON')

        return !isDifferentDestinataire
      })

      if (validOrders?.length !== dataOrder?.length) {
        setInfoOrder(
          "Vous devez sélectionner des factures avec le même client et un statut 'A_PLANIFIER' ou 'BROUILLON'"
        )
        toggleShowInfo()
        setOrdersForConnaissement([])
      } else {
        setOrdersForConnaissement(validOrders)
      }
    } else {
      // Désélectionne toutes les commandes
      setOrdersForConnaissement([])
    }
  }

  const handlefilteredOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
  }

  const filteredOrders = async (
    event: React.FormEvent | undefined,
    token: string | null,
    filteringData: filteringDataType,
    currentPage: number,
    itemPerPage: number,
    totalPage: number,
  ) => {
    if (event !== undefined) {
      event?.preventDefault()
    }
    // Filtrer les paramètres qui ne sont pas définis ou sont vides
    const filteredParams = Object.entries(filteringData)
      .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
      .map(([key, value]: [string, string]) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
      .join('&') // Les concatène avec '&'
    setIsLoading(true)
    setDataOrder([])
    try {
      if(!isFiltering){

        const response = await OrdersService.filteredOrder(
          token,
          filteredParams,
          0,
          itemPerPage
        )
        setTotalPages(response.data.last_page) // Nombre total de pages
        setDataOrder(_transformDataToNested(response.data.data))
        setIsFiltering(true)
      }else{
        const response = await OrdersService.filteredOrder(
          token,
          filteredParams,
          currentPage,
          itemPerPage
        )
        setTotalPages(response.data.last_page) // Nombre total de pages
        setDataOrder(_transformDataToNested(response.data.data))
        setIsFiltering(true)
      }
      
      
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsFiltering(true)
      setIsLoading(false)
    }
  }


  const handlePageChange = (pageNumber: number) => {
    if (dataStore.token && dataStore.token !== '') {
      if (isFiltering) {
      filteredOrders(evnt, dataStore?.token, filteringData, pageNumber, itemPerPage, totalPages)
    } else {
      _getOrdersData2(
        dataStore?.token,
        pageNumber,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage,
        itemPerPage
      )
    }
    }
    setCurrentPage(pageNumber)
  }

  const searchPlanningProps: SearchPlanningType = {
    ordersForConnaissement,
    setOrdersForConnaissement,
    handleCloseSearchPlanning,
    toggleShowA,
    toggleShowBrouillon,
    dataOrder,
    setDataOrder,
    naviresData,
  }
  const planningButtonProps: PlanningButtonType = {
    ordersForConnaissement,
    handleShowSearchPlanning,
  }

  const toastCurrentTripProps = { showA, toggleShowA }
  const toastSendedBrouillonProps = { showBrouillon, toggleShowBrouillon }
  const toastInfoProps = { showInfo, toggleShowInfo, infoOrder }
  // const toastErrorOrderProps = {showErrorOrder, toggleShowErrorOrder, errorOrderMessage};

  const SearchPlanningModalProps: SearchPlanningModalType = {
    showSearchPlanning,
    handleCloseSearchPlanning,
    searchPlanningProps,
  }
  const detailOrderModalProps: DetailOrderModalType = {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setErrorOrderMessage,
    setIsLoading,
    currentPage,
    setTotalPages,
    setInfoOrder,
    toggleShowInfo,
    setOrdersForConnaissement,
    itemPerPage,
  }
  const orderFilterProps: OrderFilterType = {
    handleSelectAll,
    dataOrder,
    ordersForConnaissement,
    handlefilteredOrder,
    filteringData,
    setFilteringData,
    isFiltering,
    setIsFiltering,
    currentPage,
    setDataOrder,
    setTotalPages,
    isLoading,
    setIsLoading,
    setErrorOrderMessage,
    naviresData,
    itemPerPage,
  }

  const orderBodyTableProps = {
    dataOrder,
    setDataOrder,
    ordersForConnaissement,
    setSelectedOrder,
    handleShow,
    errorOrderMessage,
    setErrorOrderMessage,
    isLoading,
    setInfoOrder,
    toggleShowErrorOrder,
    toggleShowInfo,
    handleSelectOrders,
  }



  return (
    <div className='p-3 pb-5 mb-5'>
      <h3 className='text-secondary'>Factures</h3>
      <Form
        onSubmit={(event) =>
          filteredOrders(event, dataStore.token, filteringData, currentPage, itemPerPage, totalPages)
        }
      >
        <Table striped hover responsive className=' border'>
          <OrderTableTitle />
          <OrderFilter orderFilterProps={orderFilterProps} />
          <OrderBodyTable orderBodyTableProps={orderBodyTableProps} />
        </Table>
      </Form>

      <div className='d-flex align-items-center mb-5'>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>

      <PlanningButton planningButtonProps={planningButtonProps} />

      <SearchPlanningModal SearchPlanningModalProps={SearchPlanningModalProps} />
      <DetailOrderModal detailOrderModalProps={detailOrderModalProps} />

      <ToastSendedBrouillon toastSendedBrouillonProps={toastSendedBrouillonProps} />
      <ToastCurrentTrip toastCurrentTripProps={toastCurrentTripProps} />
      <ToastInfo toastInfoProps={toastInfoProps} />
    </div>
  )
}
