import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import userStore, { UserState } from '../../stores/userStore'

export default function SideMenu() {

  const dataStore = userStore((state: UserState) => state)

  const isAdmin = dataStore?.roles && dataStore.roles[0]?.name === 'admin'
  const isSuperAdmin = dataStore?.roles && dataStore.roles[0]?.name ==='super_admin'
  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name ==='transporteur'
  return (
    <div className='p-abso bg-body-tertiary border-end pt-2'>
      {!isTransporter && (
        <>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/factures'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className=''>
                  {' '}
                  <i className='ri-file-text-line fs-5'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start'>Factures</Col>
              </Row>
            </Link>
          </Container>
          <Container className='py-3'>
            <Link
              className='text-decoration-none '
              to='/connaissements'
            >
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-sailboat-line fs-5'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Connaissements
                </Col>
              </Row>
            </Link>
          </Container>
        </>
      )}

      {isTransporter && (
        <>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/connaissements-demande'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-list-view fs-5 text-info'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Demandes
                </Col>
              </Row>
            </Link>
          </Container>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/connaissements-sous-reserve'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-file-edit-line fs-5 text-orange'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Sous Réserves
                </Col>
              </Row>
            </Link>
          </Container>
          
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/connaissements-officialise'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-sailboat-line fs-5 text-green'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Officialisés
                </Col>
              </Row>
            </Link>
          </Container>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/demandes-refusees'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-file-close-line fs-5 text-danger'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Demande refusée
                </Col>
              </Row>
            </Link>
          </Container>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/embarquements-refuses'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2} className='m-auto'>
                  {' '}
                  <i className='ri-close-line fs-5 danger-text'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
                  Embarquements refusés
                </Col>
              </Row>
            </Link>
          </Container>
        </>
      )}
      {isSuperAdmin && (
        <Container className='py-3'>
          <Link className='text-decoration-none ' to='/compagnies'>
            <Row className=' menu-link text-center'>
              <Col xs={12} lg={2}>
                {' '}
                <i className='ri-community-line fs-5'></i>
              </Col>{' '}
              <Col className='m-auto d-none d-sm-block text-lg-start'>Compagnies</Col>
            </Row>
          </Link>
        </Container>
      )}
      {(isAdmin || isSuperAdmin) && (
        <Container className='py-3'>
          <Link className='text-decoration-none ' to='/utilisateurs'>
            <Row className=' menu-link text-center'>
              <Col xs={12} lg={2}>
                {' '}
                <i className='ri-user-line fs-5'></i>
              </Col>{' '}
              <Col className='m-auto d-none d-sm-block text-lg-start'>Utilisateurs</Col>
            </Row>
          </Link>
        </Container>
      )}
      {/* TODO: ajouter les permissions dans le menu
      <Container className='py-3'>
        <Link className='text-decoration-none ' to='/permissions'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2}>
              {' '}
              <i className='ri-list-check-3 fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-none d-sm-block text-lg-start'>Permissions</Col>
          </Row>
        </Link>
      </Container> */}
    </div>
  )
}
