import axios from "axios";

const API_URL: string | undefined  = process.env.REACT_APP_END_POINT

class TrajetsService {
  getTrajetByNavireId(
    idNavire: string | null,
    page: string,
    limit: string,
    dateDebut: string,
    dateFin: string
  ) {
    return axios.get(
      API_URL  +
        '/api/v1/public/trajets?idNavire=' +
        idNavire +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=dateDepart,heureDepart,asc&dateDebut=' +
        dateDebut +
        '&dateFin=' +
        dateFin +
        ''
    )
  }
  getTrajetByIslandId(
    idIle: string | null,
    page: number,
    limit: number,
    dateDebut: string,
    dateFin: string
  ) {
    return axios.get(
      API_URL +
        '/api/v1/public/trajets/destinations?idIle=' +
        idIle +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=dateDepart,heureDepart,asc&dateDebut=' +
        dateDebut +
        '&dateFin=' +
        dateFin +
        ''
    )
  }
  getPlanningNavette(
    idNavire: number,
    idIleDepart: number,
    dateDebut: string,
    dateFin: string
  ) {
    return axios.get(
      API_URL +
        '/api/v1/public/trajets/planning-navette?idNavire=' +
        idNavire +
        '&idIleDepart=' +
        idIleDepart +
        '&dateDebut=' +
        dateDebut +
        '&dateFin=' +
        dateFin +
        ''
    )
  }
  getTest() {
    return axios.get(
      API_URL +
        '/public/trajets/navettes?idIleDepart=29&idIleArrivee=30&dateDebut=2024-08-13&dateFin=2024-08-18'
    )
  }
  getPlanningMoorea(
    idNavire: number,
    dateDebut: string,
    dateFin: string,
    idIleDepart: number
  ) {
    return axios.get(
      API_URL +
        '/api/v1/public/trajets/planning-moorea?idNavire=' +
        idNavire +
        '&dateDebut=' +
        dateDebut +
        '&dateFin=' +
        dateFin +
        '&idIleDepart=' +
        idIleDepart +
        '&limit=5'
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TrajetsService();


//https://www.revatua.gov.pf/api/v1/public/trajets/planning-navette?idNavire=6&idIleDepart=29&dateDebut=2024-08-20&dateFin=2024-09-31
//https://www.revatua.gov.pf/api/v1/public/trajets/planning-navette?idNavire=6&idIleDepart=29&dateDebut=2024-08-13&dateFin=2024-09-31