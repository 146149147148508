

import {create} from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type UserState = {
  id: string | null;
  name: string | null;
  roles: any | null;
  email: string | null;
  token: string | null;
  company: any | null;
  id_company: number | null;
  name_company: string | object | null;
  slug_company: any | null;
  access_token :string;
  authLogin: any;
  authLogout: () => void;
};

const userStore = create(
  persist<UserState>(
    (set) => ({
      // initial state
      id: null,
      name: null,
      roles: null,
      email: null,
      token: '',
      company: null,
      id_company: null,
      name_company: null,
      slug_company: null,
      access_token: '',

      // methods for manipulating state
      authLogin: (
        id: string | null,
        name: string | null,
        roles: any | null,
        email: string | null,
        token: string,
        company: any | null,
        id_company: number | null,
        name_company: string | object | null,
        slug_company: string | null,
        access_token: string,
      ) =>
        set((state: UserState) => ({
          id: id !== null ? id : state.id,
          name: name !== null ? name : state.name,
          roles: roles !== null ? roles : state.roles,
          email: email !== null ? email : state.email,
          token: token !== '' ? token : state.token,
          company: company !== null ? company : state.company,
          id_company: id_company !== null ? id_company : state.id_company,
          name_company: name_company !== null ? name_company : state.name_company,
          slug_company: slug_company !== null ? slug_company : state.slug_company,
          access_token: access_token !== '' ? access_token : state.access_token,
        })),
      authLogout: () =>
        set((state: any) => ({
          id: null,
          name: null,
          roles: null,
          email: null,
          token: '',
          company: null,
          id_company: null,
          name_company: null,
          slug_company: null,
          access_token: '',
        })),
    }),
    {
      name: "userLog", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage sessionStorage' is used
    }
  )
);

export default userStore;