import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT
const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT

class ConnaissementService {
  postBrouillonConnaissement(token: string, data: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/brouillons`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }
  
  postDemandeConnaissement(token: string, data: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }

  getConnaissement(token: string, page: number, limit: any) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getConnaissementSort(token: string, page: number, limit: number, sort: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=${sort}&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getTransporterByStatus(token: string, page: number, limit: any, status: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}&evenementConnaissement=${status}`,
      // url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getTransporterConnaissement(token: string, page: number, limit: any) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?demandesParArmateur=false&sort=id,desc&limit=${limit}&page=${page}&evenementConnaissement=OFFICIALISE`,
      // url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getTransporterConnaissementSousReserve(token: string, page: number, limit: any) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
    url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&limit=${limit}&page=${page}&evenementConnaissement=OFFICIALISE_SOUS_RESERVE`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }


  getPDFConnaissement(token: string, id: number, idEven: number, num: string, setData: any) 
  {
    axios(`${API_URL}/api/v1/connaissements/${id}/pdf/${idEven}?isDemandeParArmateur=false`, {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    })
  .then(response => {
  //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
      
        setData(fileURL);
        
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', 'connaissement_' + num + '.pdf'); // Nom du fichier à télécharger
      document.body.appendChild(link);
      link.click();
      link.remove();
  })
  .catch(error => {
      console.log(error);
  });
  }
  
  getPDFConnaissementToRead(token: string, id: number, idEven: number, setData: any) 
  {
    axios(`${API_URL}/api/v1/connaissements/${id}/pdf/${idEven}?isDemandeParArmateur=false`, {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    })
  .then(response => {
  //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setData(fileURL);
  })
  .catch(error => {
      console.log(error);
  });
  }
  
  getMultiPDFConnaissementToRead(token: string, id: number, idEven: number, setPdfData: any) 
  {
    axios(`${API_URL}/api/v1/connaissements/${id}/pdf/${idEven}?isDemandeParArmateur=false`, {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    })
  .then(response => {
  //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        
        
        setPdfData((prevData: any) => [...prevData, fileURL]);
     
  })
  .catch(error => {
      console.log(error);
  });
  }

  getFilteredConnaissement(token: string, page: number, filteringData: string, limit: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?${filteringData}&sort=id,desc&limit=${limit}&page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  getFilteredConnaissementSort(token: string, page: number, filteringData: string, limit: number, sort: string) {
    
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=${sort}&demandesParArmateur=false&limit=${limit}&page=${page}${filteringData && "&" + filteringData}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  getFilteredTransporterConnaissement(token: string, page: number, filteringData: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&page=${page}&${filteringData}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  getFilteredTransporterConnaissementByStatus(token: string, page: number, filteringData: string, status: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes?sort=id,desc&page=${page}&${filteringData}&evenementConnaissement=${status}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }

  getrefreshToken(token: string | null, id: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL_TOTARA}/api/v1/companies/${id}/token`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  updateConnaissement(token: string, data: any, id: number) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/${id}/changeretat`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }
  updateNbPalette(token: string, data: any, id: number) {
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/brouillons/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }

  deleteBrouillon(token: string, id: number) {
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,//demandes/43601?isDemandeParArmateur=false
      url: `${API_URL}/api/v1/connaissements/demandes/${id}?isDemandeParArmateur=false`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  

  getPdf(token: string, id: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/${id}/etiquette`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
}

 
// eslint-disable-next-line import/no-anonymous-default-export
export default new ConnaissementService();
