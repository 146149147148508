import React from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PdfViewer = ({ pdfUrl }: any) => {
  const defaultLayout = defaultLayoutPlugin()
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Worker workerUrl={`/pdf.worker.min.js`}>
    
          <div style={{ marginBottom: '20px' }}>
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayout]} />
          </div>
    
        {/* <Viewer fileUrl={pdfUrl} plugins={[defaultLayout]} /> */}
      </Worker>
    </div>
  )
}

export default PdfViewer
