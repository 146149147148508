import React from 'react'
import { Button, Container, Dropdown, Form, InputGroup, Modal, Spinner, Table } from 'react-bootstrap'
import FloatingAddButton from '../../component/ui/FloatingAddButton'
import ModalUpdateUser from '../../component/users/ModalUpdateUser';
import UserService from '../../services/user/UserService';
import userStore, { UserState } from '../../stores/userStore';
import { _getUsersData } from '../../utils/api/totaraApi';
import { ToastUpdateUserSuccess } from '../../component/ui/Toast/Toastes';
import { useOutletContext } from 'react-router-dom';
import { UserType } from '../../definitions/UserType';
import { ContextType } from '../../definitions/ComponentType';


export default function Users() {
  const {userData, setUserData} = useOutletContext<ContextType>()
 

  const dataStore = userStore((state: UserState) => state)

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
 
  const [selectedUserData, setSelectedUserData] = React.useState<UserType | undefined>();

  //Modal update user
  const [showUpdateUser, setShowUpdateUser] = React.useState<boolean>(false);
  const handleCloseUpdateUser = () => setShowUpdateUser(false);
  const handleShowUpdateUser = () => setShowUpdateUser(true);
  //Modal update user
  const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
  const handleCloseDeleteUser = () => setShowDeleteUser(false);
  const handleShowDeleteUser = () => setShowDeleteUser(true);

  //Toast update success
  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false);
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess);

React.useEffect(() => {
  _getUsersData(dataStore.token, setUserData, setIsLoading)
}, [dataStore.token, setUserData])



const handleFilterUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value
  // if (value.length > 2) {
    const filteredData = userData?.filter((item: UserType) => {
      return (
        ((item?.id).toLocaleString()).toLowerCase().includes(value?.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        (item?.role && item?.role[0]?.name?.toLowerCase()?.includes(value?.toLowerCase())) ||
        (item?.companies && item?.companies[0]?.name?.toLowerCase()?.includes(value?.toLowerCase())) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase())
      )
    })
    if(setUserData){

      setUserData(filteredData)
    }
  // }
  if (value.length === 0) {
    _getUsersData(dataStore.token, setUserData, setIsLoading)
  }
}


const handleDeleteUser = async (token: string | null, userId: number | undefined) =>{
  setIsLoading(true)

  try{
const response = await UserService.deleteUsers(token, userId)
    handleCloseDeleteUser()
    _getUsersData(dataStore.token, setUserData, setIsLoading)
    setIsLoading(false)
    if(response) {
    }

  }catch(error){
    console.log(error)
    handleCloseDeleteUser()
    setIsLoading(false)

  }

}

 


  const modalUpdateUserProps = {
    showUpdateUser,
    handleCloseUpdateUser,
    selectedUserData,
    setSelectedUserData,
    setUserData,
    setShowUpdateSuccess,
    setIsLoading,
    isLoading
  }
  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }


  return (
    <div className='p-1 p-lg-3 pb-5 mb-5 w-100'>
      <h3 className='text-secondary'>Utilisateurs</h3>
      <div>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1' className='bg-secondary shadow border-0'>
              <i className='ri-search-line text-light'></i>
            </InputGroup.Text>
            <Form.Control
              className='border'
              type='text'
              autoComplete='on'
              placeholder='Recherche'
              onChange={handleFilterUsers}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <Table striped hover responsive className='responsive-font-small border  py-5 mb-5'>
        <thead className=''>
          <tr>
            <th>Id</th>
            <th>Compagnies</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Rôle</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            userData?.map((user: UserType, indx: number) => (
              <tr key={indx}>
                <td className='pointer p-0 p-md-2'>{user.id}</td>
                <td className='pointer p-0 p-md-2'>
                  {user?.companies?.map((company: any, index: number) => (
                    <span key={index}>
                      {
                        company.name
                        // .slice(0, 10)
                      }{' '}
                      {user?.companies?.length !== index + 1 && '/ '}
                    </span>
                  ))}
                  {/* {user?.companies && user?.companies[0]?.name} */}
                </td>
                <td className='pointer p-0 p-md-2'>{user.name}</td>
                <td className='pointer p-0 p-md-2'>{user.email}</td>
                <td className='pointer p-0 p-md-2'>{user.role && user.role[0]?.name}</td>
                <td className='pointer p-0 p-md-2'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='transparent'
                      id='dropdown-basic'
                      className='border-0 no-chevron'
                    >
                      <b>
                        {' '}
                        <i className='ri-more-2-line'></i>
                      </b>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='end'>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedUserData(user)
                          handleShowUpdateUser()
                        }}
                      >
                        <i className='ri-pencil-line'></i> Modifier
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setSelectedUserData(user)
                          handleShowDeleteUser()
                        }}
                      >
                        <i className='ri-delete-bin-2-line'></i> Supprimer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
        </Container>
      )}

      <ModalUpdateUser {...modalUpdateUserProps} />
      <FloatingAddButton />
      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />

      <Modal show={showDeleteUser} onHide={handleCloseDeleteUser}>
        <Modal.Header closeButton className='border border-3 border-danger border-bottom-0'>
          <Modal.Title>
            {' '}
            <i className='ri-error-warning-line text-danger fs-3'></i> Supprimer un utilisateur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='border border-3 border-danger border-bottom-0 border-top-0'>
          &Ecirc;tes-vous sûr de vouloir continuer cette action ?
          <br />
          L'utilisateur <strong>{selectedUserData?.name}</strong> sera définitivement
          supprimer.
        </Modal.Body>
        <Modal.Footer className='border border-3 border-danger border-top-0'>
          <Button variant='secondary' onClick={handleCloseDeleteUser}>
            Annuler
          </Button>
          <Button
            variant='danger'
            onClick={() => handleDeleteUser(dataStore.token, selectedUserData?.id)}
          >
            {isLoading ? (
              <span className='text-light'>
                <Spinner size='sm' variant='light' /> loading
              </span>
            ) : (
              <span className=''>Supprimer</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
