import React from 'react'

export default function BillOfTableTitle({ BillOfTableTitleProps }: any) {
  const { sortConfig, setSortConfig } = BillOfTableTitleProps
  return (
    <thead>
      <tr>
        <th></th>
        <th>
          N°<span className='d-none d-sm-inline'>Connaiss...</span>
        </th>
        <th>Client</th>
        <th>Navire</th>
        <th>Statut</th>
        <th style={{ minWidth: '105px' }}>
          <span className='font-85'>Date départ</span>
          <span className='pointer'>
            <i
              className={`ri-arrow-up-s-fill ${
                sortConfig === 'voyage.dateDepart,asc' ? 'text-remora-secondary' : 'text-dark'
              }`}
              onClick={() => setSortConfig('voyage.dateDepart,asc')}
            ></i>{' '}
            <i
              className={`ri-arrow-down-s-fill ${
                sortConfig === 'voyage.dateDepart,desc' ? 'text-remora-secondary' : 'text-dark'
              }`}
              onClick={() => setSortConfig('voyage.dateDepart,desc')}
            ></i>
          </span>
        </th>
        <th>Ile d'arrivée</th>
        <th style={{ minWidth: '105px' }}>
          <span className='font-85'>Date Arrivée</span>
          <span className='pointer'>
            <i
              className={`ri-arrow-up-s-fill ${
                sortConfig === 'voyage.dateArrivee,asc' ? 'text-remora-secondary' : 'text-dark'
              }`}
              onClick={() => setSortConfig('voyage.dateArrivee,asc')}
            ></i>{' '}
            <i
              className={`ri-arrow-down-s-fill ${
                sortConfig === 'voyage.dateArrivee,desc'
                  ? 'text-remora-secondary'
                  : 'text-dark'
              }`}
              onClick={() => setSortConfig('voyage.dateArrivee,desc')}
            ></i>
          </span>
        </th>
        <th>Action</th>
      </tr>
    </thead>
  )
}
